@import "../../../variables.scss";

.banner {

    
    
    width: 100%;
    height: 85vh;
    background-color: $main-901;
    position: relative;
    overflow: hidden;
    background-image: url("../../assets/images/lines.svg");
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    @include rowflex(center, center);

    @include breakpoint(laptop){
        // height: 75vh;
        height: calc(90% + 1.5px);
        @include rowflex(center, center);


    }


    

    @include breakpoint(tablet){
        // height: 85vh;
        height: calc(100% + 1.5px);
        // height: calc(100% + 1.5px);
        
    }

    @include breakpoint(phablet){
        height: 80vh;
    }


    

    // @media only screen 
    // and (min-width: 14in) 
    // and (orientation: landscape) 
    // and (-webkit-min-device-pixel-ratio: 2) {
    //     // width: 80vw;
    // }

    @media only screen 
  and (min-width: 768px) 
  and (max-width: 1024px) 
  and (orientation: portrait) 
  and (-webkit-min-device-pixel-ratio: 2) {
        height: 55vh;
    }

    @media only screen 
  and (min-width: 768px) 
  and (max-width: 1024px) 
  and (orientation: landscape) 
  and (-webkit-min-device-pixel-ratio: 2) {
        // height: 80vh;
        height: calc(100vw / 1.6);
    }

   
    @media only screen 
    and (min-width: 600px) 
    and (max-width: 800px) 
    and (orientation: portrait) 
    and (-webkit-min-device-pixel-ratio: 2) {
          height: 65vh;
          
      }
    // @include breakpoint(desktop){

    //     height: 85vh;
    //     background-color: red;
    //     // @include rowflex(center, center);
    // }



    @include breakpoint(mobileonly){
        height: 55vh;
        overflow: hidden;
         
    }

    @media only screen 
    and (min-width: 414px) 
    and (max-width: 736px) 
    and (-webkit-min-device-pixel-ratio: 2) 
    and (orientation: portrait){
    height: 55vh;
  }
    
    
    &__inner {  
        margin-top: 100px;
        height: 80%;
        @include colflexx(center);

        @include breakpoint(laptop){
            height: 85%;


        }

        &__text {
            height: fit-content;
            @include colflexx(center);
            text-align: center;
            
            @include breakpoint(mobileonly){
                width: 90%;
            }
            p:nth-child(1){
                margin: 0;
                @include fontMedium(1.5em, 1em, $main-100);

                @include breakpoint(mobileonly){
                    @include fontMedium(1.2em, 1em, $main-100);
                    
                }
                @include breakpoint(phablet){
                    @include fontMedium(1.2em, 1em, $main-100);
                }
            }
            p:nth-child(2){
                // width:55%;
                margin: 0;
                margin-top: 10px;
                @include fontBold(2.5em, 1.4em, $main-100);

                @include breakpoint(mobileonly){
                    // width: 90%;
                    @include fontBold(1.5em, 1.4em, $main-100);
                }
                @include breakpoint(phablet){
                    // width: 70%;
                    @include fontBold(2em, 1.4em, $main-100);
                }
                @include breakpoint(tablet){
                    // width: 70%;
                    @include fontBold(2.2em, 1.4em, $main-100);
                }
                

                @include breakpoint(desktop){
                    // width: 70%;
                    @include fontBold(2.5em, 1.4em, $main-100);
                }

                @include breakpoint(laptop){
                    // width: 70%;
                    @include fontBold(2.2em, 1.4em, $main-100);
                }

                
            }
        }
        &__tab {
            position: absolute;
            width: 900px;
            // top: 350px;
            // bottom: -200px;

            
           

           

            
            @include breakpoint(mobileonly){
                width: 85vw;
                bottom: -80px;
                // bottom: 20vw;
            }

            // @media only screen 
            //     and (min-device-width: 768px) 
            //     and (max-device-width: 1024px) 
            //     and (-webkit-min-device-pixel-ratio: 1) {

            //         width: 700px;
            //     bottom: -100px;
            //     }
            @include breakpoint(desktop){
                width: 45vw;
                bottom: -150px;
            }
            @include breakpoint(laptop){
                width: 650px;
                bottom: calc(10% - 190px);
            }
            @include breakpoint(tablet){
                width: 80vw;
                bottom: -150px;

                // bottom: calc(10% - 190px);

            }
            
            @media only screen 
            and (min-width: 768px) 
            and (max-width: 1024px) 
            and (orientation: landscape) 
            and (-webkit-min-device-pixel-ratio: 2) {
                width: 70vw;
                bottom: -200px;
            }

            

            @include breakpoint(phablet){
                width: 500px;
                bottom: -100px;

            }

            
          
        }

        &__icons {
            margin-top: 50px;
            @include breakpoint(mobileonly){
                // position: absolute;
                margin-top: 20px;
                width: 100%;
            }
            @include breakpoint(phablet){
                // position: absolute;
                margin-top: -50px;
                width: 100%;
            }
            @include breakpoint(tablet){
                // position: absolute;
                margin-top: 0px;
                width: 100%;
            }

            @include breakpoint(laptop){
                // position: absolute;
                margin-top: 50px;

                width: 100%;
            }

            @media only screen 
            and (min-device-width: 768px) 
            and (max-device-width: 1024px) 
            and (-webkit-min-device-pixel-ratio: 1) {
          
                margin-top: 50px;
              }
              @media only screen 
              and (min-width: 1366px) 
              and (max-width: 1366px)
              and (orientation: landscape) 
              and (-webkit-min-device-pixel-ratio: 1) {
                  margin-top: 0px;
            }
            
            // @include breakpoint(desktop){
            //     // margin-top: -50px;

            //     width: 100%;
                
            // }
        }

    }
    
    .custom-shape-divider-bottom-1648766796 {
        position: absolute;
        bottom: 0;
        left: 0;
        width: 100%;
        overflow: hidden;
        line-height: 0;
        transform: rotate(180deg);
    }
    
    .custom-shape-divider-bottom-1648766796 svg {
        position: relative;
        display: block;
        width: calc(139% + 1.3px);
        height: 177px;
        transform: rotateY(180deg);

        @include breakpoint(mobileonly){
            width: calc(160% + 1.5px);
        }
    }
    
    .custom-shape-divider-bottom-1648766796 .shape-fill {
        fill: #FFFFFF;
    }
    
}