@import "../../../variables.scss";


button {
    outline: none;
    border: none;
    width: fit-content;
    padding-left: 20px;
    padding-right: 20px;
    height: 50px;
    text-transform: capitalize;
    border-radius: 5px;
    background-color: $main-900;
    transition: all .5s ease;
    cursor: pointer;
    @include fontRegular(1.2em,1em, #fff);

    @include breakpoint(mobileonly){
        height: 50px;
    }

    @include breakpoint(tablet){
        height: 50px;
    }

    &:hover {
        background-color: $main-902;
        transform: scale(1.1);
        // padding: 0px 30px 0px 30px;
        // height: 60px;
    }
}