@import "../../../variables.scss";

.know {
    width: 80%;
    height: fit-content;
    margin: 0 auto;
    margin-bottom: 100px;
    @include rowflex(flex-start,space-between);

    @include breakpoint(mobileonly){
        width: 90%;
        margin-top: 180px;
        @include colflexx(flex-start);
    }
    @include breakpoint(phablet){
        width: 90%;
        @include colflexx(flex-start);
    }

    @include breakpoint(tablet){
        width: 90%;
        @include colflexx(flex-start);
    }

    @include breakpoint(laptop){
        width: 85%;

        @include rowflex(flex-start,space-between);
    }

    &__left {
        flex: 1;
        @include colflexx(flex-start);

        @include breakpoint(mobileonly){
            flex: 1;
            width: 90%;
            text-align: center;
            @include colflexx(center);
            margin: 0 auto;
            margin-bottom: 25px;
        }

        @include breakpoint(phablet){
            flex: 1;
            width: 90%;
            text-align: center;
            margin-bottom: 50px;
            @include colflexx(center);
        }

        @include breakpoint(tablet){
            flex: 1;
            width: 80%;
            margin: 0 auto;
            text-align: center;
            @include colflexx(center);
        }

        p:not(:first-child){
            margin-top: 20px;
        }
        p:nth-child(1){
            margin: 0;
            @include fontBold(2.5em, 1.2em, $main-901);

            @include breakpoint(mobileonly){
                @include fontBold(1.75em, 1.2em, $main-901);
            }
        }
        p:nth-child(2){
            margin: 0;
            margin-top: 20px;
            @include fontRegular(1.2em, 1.4em, $text);

            @include breakpoint(mobileonly){
                @include fontRegular(.9em, 1.4em, $text);
            }
        }
        p:nth-child(3){
            margin: 0;
            margin-top: 20px;
            cursor: pointer;
            transition: all .5s ease;
            @include fontMedium(1.25em, 1em, $main-900);

            @include breakpoint(mobileonly){
                @include fontMedium(1.2em, 1.4em, $main-900);
            }
            &:hover {
                transform: scale(1.1);
                color: $main-902;
            }
            i {
                margin-left: 10px;
            }
        }
    }

    &__right {
        flex: 2;
        width: fit-content;
        @include rowflex(flex-start,flex-end);
        flex-wrap: wrap;
        gap: 30px;

        @include breakpoint(mobileonly){
            flex: 1;
            margin-top: 50px;
            width: 100%;
            gap: 0px;
            align-items: center;
            justify-content: center;
        }
        @include breakpoint(phablet){
            flex: 1;
            margin-top: 50px;
            width: 100%;

            
        }
        @include breakpoint(tablet){
            flex: 1;
            margin: 0 auto;
            margin-top: 50px;
            width: 95%;
            gap:20px;
            @include rowflex(flex-start,flex-start);
        }

        @include breakpoint(laptop){
            flex: 2;
            // margin: 0 auto;
            // margin-top: 50px;
            width: 100%;
            gap: 30px;
            @include rowflex(flex-start,flex-end);
        }
        .singleknow:nth-child(2){
            padding-bottom: 68px;

            @include breakpoint(laptop){
                padding-bottom: 96px;
            }
        }

        .singleknow:nth-child(1){

            @include breakpoint(laptop){
                padding-bottom: 68px;
            }
        }

        .singleknow:not(:first-child){
            @include breakpoint(mobileonly){
                margin-top: 30px;
            }
        }

        .singleknow {
            background-color: #F4F6FF;
            padding: 40px;
            width: 240px;
            border-radius: 12px;
            @include colflexx(flex-start);

            @include breakpoint(mobileonly){
                width: 350px;
                @include colflexx(center);
                text-align: center;
            }

            @include breakpoint(laptop){
                width: 220px;
            }

            img {
                width: auto;
            }

            &__content {
                margin-top: 40px;
                text-align: left;

                @include breakpoint(mobileonly){
                    text-align: center;
                }

                p:not(:first-child){
                    margin-top: 20px;
                }
                p:nth-child(1){
                    margin: 0;
                    @include fontBold(1.5em, 1em, $main-901);
                }
                p:nth-child(2){
                    margin: 0;
                    margin-top: 20px;
                    @include fontLight(1.25em, 1.4em, $text);
                }
            }
            

        }
    }
}