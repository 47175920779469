@import "../../../variables.scss";

.form-input{
    @include colflexx(flex-start);
    height:fit-content;
    width: 100%;
    margin-bottom: 0px;
    @include breakpoint(mobileonly) {
        height:fit-content;
        @include colflex(flex-start, space-between);
    }
    @include breakpoint(phablet) {
        width: 100%!important;
        
    }
    @include breakpoint(tablet) {
        width: 100%!important;
        
    }
   

    .input-box {
        // width: 100%;
        flex: 3;
    }
    
   
    input[type='text'], input[type='password'], input[type='number'] {
        width: 100% !important;
        // margin-top: 30px;
        height: 60px;
        background: #F9F8F9;
        border: 1px solid $gray-400;
        box-sizing: border-box;
        border-radius: 5px;
        outline: none;
        padding-left: 20px;
        position: relative;
        margin: 0px;
        
        @include fontRegular(1em, 1em, $text);

        @include breakpoint(mobileonly) {
            width: 100%!important;
            height: 50px;
        }
        @include breakpoint(tablet) {
            width: 100% !important;
            height: 60px;
        }

        @include breakpoint(phablet) {
            width: 100% !important; //250PX
            // height: 50px;
        }
        

        &:focus {
            border: 1px solid $main-700;
            background-color: $main-100;
            color: $main-901;
        }
        &:disabled {
            cursor: not-allowed;
            color: $gray-800;
            background-color: $gray-300;
        }
    }
}
.eyes{
    position: absolute;
    right: 20px;
    top: 20px;
    width: 20;
    height: 20;
    color: $gray-600;
}

