@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@100;200;300;400;500;600;700;800;900&display=swap');

//fonts
@font-face {
    font-family: 'Trap';
    src: url('./src/assets/fonts/Trap-Light.WOFF') format('');
    font-weight: 200;
}
@font-face {
    font-family: 'Trap';
    src: url('./src/assets/fonts/Trap-Regular.WOFF') format('');
    font-weight: 300;
}

@font-face {
    font-family: 'Trap';
    src: url('./src/assets/fonts/Trap-Medium.WOFF') format('');
    font-weight: 400;
}
@font-face {
    font-family: 'Trap';
    src: url('./src/assets/fonts/Trap-SemiBold.WOFF') format('');
    font-weight: 500;
}
@font-face {
    font-family: 'Trap';
    src: url('./src/assets/fonts/Trap-Bold.WOFF') format('');
    font-weight: 600;
}
@font-face {
    font-family: 'Trap';
    src: url('./src/assets/fonts/Trap-ExtraBold.WOFF') format('');
    font-weight: 700;
}
@font-face {
    font-family: 'Trap';
    src: url('./src/assets/fonts/Trap-Black.WOFF') format('');
    font-weight: 800;
}


// Colors

$text : #6A7595;

$main-100: #F4F7FF;
$main-200: #DBE5FF;
$main-300: #C3D3FF;
$main-400: #AAC1FF;
$main-500: #8EACF9;
$main-600: #6886D7;
$main-700: #4765B5;
$main-800: #2D4993;
$main-900: #183171;
$main-901: #0A1D4F;
$main-902: #06112D;

$neutral-100: #F7F8FA;
$neutral-200: #EAEBF1;
$neutral-300: #DDE1E9;
$neutral-400: #D1D5E0;
$neutral-500: #B6BBC9;
$neutral-600: #858CA0;
$neutral-700: #676D7E;
$neutral-800: #4C5366;
$neutral-900: #2A3147;
$neutral-901: #1B2032;
$neutral-902: #15171B;

$gray-100 : #F9F8F9;
$gray-200 : #F8F6F5;
$gray-300 : #EAEAEA;
$gray-400 : #CCCCCC;
$gray-500 : #8B8C8C;
$gray-600 : #4F4F4F;
$gray-700 : #2D2F30;
$gray-800 : #151617;

$green-100 : #F0FFF6;
$green-200 : #CEFFE3;
$green-300 : #ADFFD0;
$green-400 : #8CFFBD;
$green-500 : #69FCA8;
$green-600 : #4DDA89;
$green-700 : #35B86D;
$green-800 : #219653;
$green-900 : #12743C;
$green-901 : #075227;

$yellow-100 : #FFF9F0;
$yellow-200 : #FFF3DB;
$yellow-300 : #F8E5BF;
$yellow-400 : #FCDA97;
$yellow-500 : #FBCB6A;
$yellow-600 : #FFBE3E;
$yellow-700 : #E99F0C;
$yellow-800 : #BB8211;
$yellow-900 : #936710;
$yellow-901 : #483806;


//flex

@mixin rowflex ($align, $just){
  display: flex;
  flex-direction: row;
  align-items: $align;
  justify-content: $just;
}

@mixin rowRevflex ($align, $just){
  display: flex;
  flex-direction: row-reverse;
  align-items: $align;
  justify-content: $just;
}

@mixin colflex ($align, $just){
  display: flex;
  flex-direction: column;
  align-items: $align;
  justify-content: $just;
}

@mixin rowflexx ($align){
  display: flex;
  flex-direction: row;
  align-items: $align;
  
}
@mixin rowflexxx ($align){
  display: flex;
  flex-direction: row;
  align-items: $align;
  justify-content: space-between;
  
}

@mixin colflexx ($align){
  display: flex;
  flex-direction: column;
  align-items: $align;
  
}
@mixin colflexxx ($align){
  display: flex;
  flex-direction: column-reverse;
  align-items: $align;
  
}





// font styles

@mixin font($fs, $lh, $color) {
  font-size: $fs;
  line-height: $lh;
  color: $color;
//   font-style: normal;
  font-family: 'Trap', sans-serif;
    
}

@mixin fontBody($fs, $lh, $color) {
    font-size: $fs;
    line-height: $lh;
    color: $color;
    font-style: normal;
    font-family: 'Poppins', sans-serif;
    
  }

@mixin fontLight($fs, $lh, $color) {
  @include font($fs, $lh, $color);
  font-weight: 200;
}

@mixin fontThin($fs, $lh, $color) {
  @include font($fs, $lh, $color);
  font-weight: 200;
}

@mixin fontRegular($fs, $lh, $color) {
  @include font($fs, $lh, $color);
  font-weight: 300;
}

@mixin fontMedium($fs, $lh, $color) {
  @include font($fs, $lh, $color);
  font-weight: 400;
}

@mixin fontSemiBold($fs, $lh, $color) {
    @include font($fs, $lh, $color);
    font-weight: 500;
  }

@mixin fontBold($fs, $lh, $color) {
  @include font($fs, $lh, $color);
  font-weight: 600;
}
@mixin fontExtraBold($fs, $lh, $color) {
    @include font($fs, $lh, $color);
    font-weight: 700;
  }

@mixin fontBlack($fs, $lh, $color) {
  @include font($fs, $lh, $color);
  font-weight: 800;
}

@mixin fontBodyRegular($fs, $lh, $color) {
    @include fontBody($fs, $lh, $color);
    font-weight: 300;
  }

@mixin fontBodyMedium($fs, $lh, $color) {
  @include fontBody($fs, $lh, $color);
  font-weight: 400;
}
@mixin fontBodyBold($fs, $lh, $color) {
  @include fontBody($fs, $lh, $color);
  font-weight: 500;
}

@mixin fontBodyBlack($fs, $lh, $color) {
  @include fontBody($fs, $lh, $color);
  font-weight: 700;
}




// keyframes

@mixin keyframes($animationName) {
    @-webkit-keyframes #{$animationName} {
      @content;
    }
    @-moz-keyframes #{$animationName} {
      @content;
    }
    @-o-keyframes #{$animationName} {
      @content;
    }
    @keyframes #{$animationName} {
      @content;
    }
}

@mixin animation($str) {
  -webkit-animation: #{$str};
  -moz-animation: #{$str};
  -ms-animation: #{$str};
  -o-animation: #{$str};
  animation: #{$str};      
}

@include keyframes(slide-in) {
  0% { 
    opacity: 0;
    padding-top: 20px;
    @include animation('fade 0.7s 1'); 
  }

  90% { 
    padding-top: 0px; 
    @include animation('fade 0.7s 1'); 
  }
}

@include keyframes(fade) {
  0% { 
    opacity: 0; 
  }

  90% { 
    padding-top: 1; 
  }
}


@mixin animate($name, $duration, $iteration, $direction) {
  -webkit-animation-duration: $duration;
  -moz-animation-duration: $duration;
  -o-animation-duration: $duration;
  animation-duration: $duration;
  -webkit-animation-iteration-count: $iteration;
  -moz-animation-iteration-count: $iteration;
  -o-animation-iteration-count: $iteration;
  animation-iteration-count: $iteration;
  -webkit-animation-name: $name;
  -moz-animation-name: $name;
  -o-animation-name: $name;
  animation-name: $name;
  -webkit-animation-direction: $direction;
  -moz-animation-direction: $direction;
  -o-animation-direction: $direction;
  animation-direction: $direction;
}


/*********************
BREAKPOINTS
*********************/

@mixin breakpoint($point) {
  @if $point == desktop {
    @media (min-width: 1200px) and (max-width: 1920px)  { @content ; }
  }
  @else if $point == laptop {
    @media (min-width: 1024px) and (max-width: 1366px)  { @content ; }
    // @media (max-width: 40em) and (max-width: 56em) { @content ; }
  }
   @else if $point == tablet {
    // @media (min-width: 50em) { @content ; }
    // @media (min-width: 800px) and (max-width: 1224px) {@content;}
    @media (min-width: 768px) and (max-width: 1024px) {@content;} 
  }
  @else if $point == phablet {
    // @media (min-width: 37.5em)  { @content ; }
    // @media (min-width: 480px) and (max-width: 768px) { @content; }
    @media (min-width: 600px) and (max-width: 800px) { @content; }
  }
 @else if $point == mobileonly {
    // @media (max-width: 37.5em)  { @content ; }
    // @media (min-width: 320px) and (max-width: 480px) { @content; }
    @media (min-width: 320px) and (max-width: 600px) { @content; }
  }
}