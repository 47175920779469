@import "../../../variables.scss";

header {
    @include rowflex(center,center);
    width: 100%;
    height: 120px;
    background-color: #fff;
    position: -webkit-sticky !important;
    position: sticky !important;
    top: 0px;
    z-index: 1;
    border-bottom: 1px solid $gray-300;

    @include breakpoint(mobileonly){
        overflow: hidden;
    }
    .header {
        width: 90%;
        @include rowflex(center,space-between);

        @include breakpoint(mobileonly){
            @include rowflex(center,flex-start);
        }

        img {
            width: auto;
        }

        button {
            @include breakpoint(mobileonly){
                display: none;
            }
        }
    }

}