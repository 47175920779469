@import "../../../variables.scss";

.offering {
    width: 80%;
    height: 350px;
    padding: 60px 0px 0px 0px;
    position: relative;
    background-color: $main-901;
    margin: 0 auto;
    border-radius: 12px;
    margin-bottom: 150px;
    @include rowflex(center,center);

    @include breakpoint(mobileonly){
        height: fit-content;
        padding: 40px 20px 40px 20px;
        height: 550px;
        margin-bottom: 150px;
        margin-top: 100px;
    }

    @include breakpoint(phablet){
        height: fit-content;
        padding: 40px 20px 40px 20px;
        height: 650px;
        margin-bottom: 250px;
        margin-top: 100px;
    }

    @include breakpoint(tablet){
        height: fit-content;
        padding: 40px 20px 40px 20px;
        height: 650px;
        margin-bottom: 250px;
        margin-top: 100px;
    }

    @include breakpoint(laptop){
        height: fit-content;
        padding: 40px 20px 40px 20px;
        height: 400px;
        margin-bottom: 150px;
        // margin-top: 150px;
        
    }

    @include breakpoint(desktop){
        height: fit-content;
        padding: 40px 20px 40px 20px;
        height: 400px;
        margin-bottom: 150px;
        margin-top: 150px;
        
    }

    &__content {
        width: 90%;
        margin-top: 80px;
        @include rowflex(flex-start, space-between);

        @include breakpoint(mobileonly){
            margin-top: 30px;
            @include colflexx(center);
        }
        @include breakpoint(phablet){
            margin-top: 30px;
            @include colflexx(center);
        }

        @include breakpoint(tablet){
            margin-top: 30px;
            @include colflexx(center);

        }

        @include breakpoint(laptop){
            margin-top: 150px;

        }

        @include breakpoint(desktop){
            margin-top: 150px;

        }

        .singleoffering:not(:first-child){
            margin-left: 50px;

            @include breakpoint(mobileonly){
                margin-left: 0px;
                margin-top: 50px;
            }
            @include breakpoint(phablet){
                margin-left: 0px;
                margin-top: 50px;
            }
            @include breakpoint(tablet){
                margin-left: 0px;
                margin-top: 50px;
            }
        }
        .singleoffering {
            flex: 1;
            text-align: left;
            @include colflexx(flex-start);

            @include breakpoint(mobileonly){
                text-align: center;
                @include colflexx(center);
            }
            @include breakpoint(phablet){
                text-align: center;
                width: 80%;
                @include colflexx(center);
            }
            @include breakpoint(tablet){
                text-align: center;
                width: 80%;
                @include colflexx(center);
            }

            p:nth-child(1){
                margin: 0px;
                @include fontBold(1.5em, 1em, #fff);

                @include breakpoint(mobileonly){
                    @include fontBold(1.25em, 1em, #fff);
                }
                @include breakpoint(laptop){
                    @include fontBold(1.2em, 1.4em, #fff);
                }
            }
            p:nth-child(2){
                margin: 0px;
                margin-top: 10px;
                @include breakpoint(mobileonly){
                    @include fontLight(1em, 1.4em, #fff);
                }

                @include breakpoint(laptop){
                    @include fontLight(1em, 1.4em, #fff);
                }
                @include fontLight(1.25em, 1.4em, #fff);
            }
        }
    }

    img {
        
        position: absolute;
        
    }
    &__limage{
        float: left;
        left: 30px;
        top: -100px;
        height: 295px;
        @include breakpoint(mobileonly){
            height: 200px;
        }
        @include breakpoint(phablet){
            height: 200px;
        }
        @include breakpoint(tablet){
            height: 250px;
        }

    }
    &__rimage{
        height: 295px;
        float: right;
        right: 30px;
        top: -100px;
        bottom: 0px;
        @include breakpoint(mobileonly){
            top: 550px;
            bottom: 50px !important;
            height: 175px;
        }
        @include breakpoint(phablet){
            top: 650px;
            bottom: 10px !important;
            height: 250px;
        }
        @include breakpoint(tablet){
            top: 650px;
            bottom: 10px !important;
            height: 250px;
        }
    }
}