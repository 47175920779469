@import "../../../variables.scss";

.footer {
    width: 100%;
    height: 550px;
    // position: absolute;
    margin-top: 100px;
    bottom: 0px;
    background-color: $main-901;
    @include rowflex(center,center);
    
    @include breakpoint(mobileonly){
        padding: 50px 0px 50px 0px;
    }


    
    &__inner {
        width: 90%;
        height: fit-content;
        @include colflexx(center);

        
        img {
            width: auto;
            margin-bottom: 40px;
        }

        &__links {
            margin-bottom: 40px;
            @include rowflex(center,center);

            @include breakpoint(mobileonly){
                text-align: center;
                @include colflexx(center);
            }

            p:not(:first-child){
                margin-left: 70px;

                @include breakpoint(mobileonly){
                    margin-left: 0px;
                    margin-top: 20px;
                }
            }
            p{
                margin: 0;
                @include fontBold(1.2em, 1em, #fff);
            }
        }

        &__socials {
            margin-bottom: 40px;
            @include rowflex(center,center);
            i:not(:first-child){
                margin-left: 20px;
            }

            i{
                margin: 0;
                font-size: 3em;
                border-radius: 100%;
                color: #fff;
                cursor: pointer;
                transition: all .5s ease;
                &:hover {
                    transform: scale(1.1);
                }
            }
        }
        &__bottom {
            text-align: center;
            @include colflexx(center);
            width: 40%;
            
            @include breakpoint(mobileonly){
                width: 90%;
            }
            @include breakpoint(phablet){
                width: 90%;
            }

            @include breakpoint(tablet){
                width: 90%;
            }
            p:nth-child(1){
                margin: 0px;
                @include fontRegular(1em, 1.7em, #fff);
            }
            p:nth-child(2){
                margin: 0px;
                margin-top: 30px;
                @include fontLight(1em, 1em, #fff);
            }
    
        }
    
    }

    
}
