@import "../../../variables.scss";

.informed {
    width: 75%;
    height: 450px;
    margin: 0 auto;
    border-radius: 12px;
    // padding: 70px 0px 70px 0px;
    background-color: $main-901;
    box-shadow: 0px 4px 15px rgba(118, 156, 255, 0.3);
    @include colflex(center,center);

    @include breakpoint(mobileonly){
        width: 90%;
        height: 500px;
    }
    &__text {
        text-align: center;
        @include colflexx(center);
        width: 50%;
        margin-bottom: 30px;


        @include breakpoint(mobileonly){
            width: 75%;
            height: fit-content;
        }

        @include breakpoint(phablet){
            width: 75%;
            height: fit-content;
        }

        @include breakpoint(tablet){
            width: 75%;
            height: fit-content;
        }
        p:nth-child(1){
            margin: 0;
            @include fontBold(3em, 1.2em, $main-100);
            
            @include breakpoint(mobileonly){
                @include fontBold(1.75em, 1.2em, $main-100);
            }
            @include breakpoint(phablet){
                @include fontBold(2em, 1.2em, $main-100);
            }
            @include breakpoint(tablet){
                @include fontBold(2em, 1.2em, $main-100);
            }
        }
        p:nth-child(2){
            margin: 0;
            margin-top: 20px;
            @include fontLight(1.25em, 1.4em, #fff);

            @include breakpoint(mobileonly){
                @include fontLight(1em, 1.4em, #fff);
            }
        }
    }
    &__input {
        @include rowflex(center,center);
        width: 45%;
        gap: 10px;

        @include breakpoint(mobileonly){
            width: 80%;
            height: 150px;
            gap: 0px;
            @include colflexx(center);
        }

        @include breakpoint(phablet){
            width: 80%;
            height: 20%;
            gap: 10px;
        }

        @include breakpoint(tablet){
            width: 80%;
            height: 20%;
            gap: 10px;
        }


        button {
            flex: 1;
            height: 60px;
            width: 100px !important;

            @include breakpoint(mobileonly){
                width: 150px !important;
                flex: 0;
                padding:20px !important;
                margin-top: 10px;
            }

            &:hover{
                margin-left: 10px;
            }
        }

        .form-input {
            flex: 2;
            @include breakpoint(mobileonly){
                flex: 0;
                
            }
        }
    }
}