@import "../../../variables.scss";

.platform {
    width: 100%;
    height: 60vh;
    padding-top: 50px;
    margin-bottom: 100px;
    text-align: center;
    @include colflex(center,center);

    @include breakpoint(mobileonly){
        height: 50vh;
        // background-color: red;
        margin-top: 40px;
        padding-top: 20px;
        margin-bottom: 140px;
    }

    @include breakpoint(tablet){
        height: 50vh;
        padding-top: 20px;
    }
    @media only screen 
  and (min-width: 768px) 
  and (max-width: 1024px) 
  and (orientation: landscape) 
  and (-webkit-min-device-pixel-ratio: 2) {
        // height: 80vh;
        height: calc(100vw / 1.6);
    }
    @include breakpoint(laptop){
        height: 65vh;
        padding-top: 20px;
    }

    &__text{
        @include colflexx(center);
        width: 40%;
        @include breakpoint(mobileonly){
            width: 80%;
            // margin: 0 auto;
        }
        @include breakpoint(phablet){
            width: 70%;
        }
        @include breakpoint(tablet){
            width: 70%;
        }
        @include breakpoint(laptop){
            width: 70%;
        }
        margin-bottom: 30px;
        p:nth-child(1){
            margin: 0;
            @include fontBold(3em, 1.2em, $main-900);
            
            @include breakpoint(mobileonly){
                @include fontSemiBold(1.75em, 1.2em, $main-900);
            }

            @include breakpoint(phablet){
                @include fontSemiBold(1.8em, 1.2em, $main-900);
            }

            @include breakpoint(tablet){
                @include fontSemiBold(2.5em, 1.2em, $main-900);
            }
            @include breakpoint(laptop){
                @include fontSemiBold(2.5em, 1.2em, $main-900);
            }
        }
        p:nth-child(2){
            margin: 0;
            margin-top: 20px;
            @include fontRegular(1.25em, 1.4em, $text);
            @include breakpoint(mobileonly){
                @include fontRegular(.9em, 1.4em, $text);
            }
            @include breakpoint(phablet){
                @include fontRegular(1em, 1.4em, $text);
            }
            @include breakpoint(tablet){
                @include fontRegular(1em, 1.4em, $text);
            }
        }

    }
}